import styled from 'styled-components'

export const StyledBlogCards = styled.div`
  margin-bottom: 40px;
  max-width: 1124px;
  margin: auto 10px 56px 10px;
  padding: 0 16px;
  color: var(--brand-font-default-color);
  .slick-track {
    display: flex;
    align-items: stretch;

    .slick-slide {
      height: unset;
      > div {
        height: 100%;
      }
    }
  }

  .blog-cards-heading {
    > * {
      font-size: inherit;
      font-weight: inherit;
    }
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 100px;
  }
`

export const StyledBlogCard = styled.div`
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  .usp-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .expectation-title {
    font-style: normal;
    padding: 0 15px;
    margin: 0 0 10px;
  }

  .expectation-content {
    margin: 0;
    font-style: normal;
    letter-spacing: 0.5px;
    padding: 0 15px 20px;
    color: #6c6c6c;
    flex-grow: 1;
  }

  .learn-more {
    padding: 10px;
    border: none;
    background: var(--brand-primary-color);
    color: var(--brand-primary-white-color);
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    position: relative;
    overflow: hidden;
    -webkit-highlight: none;
    -webkit-tap-highlight-color: transparent;
  }

  @media screen and (min-width: 768px) {
    .usp-image {
      margin-bottom: 30px;
    }

    .expectation-title {
      padding: 0 15px;
      margin: 0 0 10px;
    }

    .learn-more {
      font-size: 23px;
      line-height: 31px;
    }
  }
`
