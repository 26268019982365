import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import React from 'react'
import {useCallback} from 'react'

import {StyledContainerWrap} from '..'
import {CustomSlider, Section} from '../../../ComponentsV2/common'
import OptimizedImage from '../../../ComponentsV2/common/OptimizedImage'
import Typography from '../../../ComponentsV2/common/Typography/Typography'
import {withCustomErrorBoundary} from '../../../utils/withCustomErrorBoundary'
import {StyledBlogCard, StyledBlogCards} from './BlogCards.styles'

const sliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2.3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
      },
    },
  ],
}

function BlogCard({card, onClick}) {
  const {title, content, image, backgroundColor, cta} = card || {}
  const {src, width, height} = image
  const {label = '', link = '#'} = cta || {}
  const handleClick = useCallback(
    (event) => {
      event.preventDefault()
      onClick(cta)
    },
    [onClick, cta]
  )
  if (isEmpty(card)) {
    return null
  }

  return (
    <StyledBlogCard
      as="a"
      bgColor={backgroundColor}
      href={link}
      onClick={handleClick}
    >
      <OptimizedImage
        source={src}
        aspectWidth={width}
        aspectHeight={height}
        desktopViewWidth="THIRD"
        mobileViewWidth="THIRD"
        loading="lazy"
        customClassName="usp-image"
      />
      <Typography
        text={title}
        variant="subheading-bold"
        customClassName="expectation-title"
        headingLevel={3}
      />

      <Typography
        text={content}
        variant="body-base-light"
        customClassName="expectation-content"
      />
      {label && (
        <button type="button" className="learn-more brand-Primary-btn">
          <Typography text={label} variant="cta-label-sm" />
        </button>
      )}
    </StyledBlogCard>
  )
}

function BlogCards({state = {}, actions = {}}) {
  const {cards, subtitle, title, sectionName} = state || {}
  const {onCtaClick = noop} = actions

  if (isEmpty(state) || isEmpty(cards)) {
    return null
  }
  return (
    <StyledContainerWrap data-scrollsection={sectionName}>
      <StyledBlogCards data-scrollsection={sectionName}>
        <Section
          state={{title, subtitle, headingClassName: 'blog-cards-heading'}}
        />
        <CustomSlider sliderSettings={sliderSettings}>
          {cards.map((card, index) => {
            return (
              <React.Fragment key={index}>
                <BlogCard card={card} onClick={onCtaClick} />
              </React.Fragment>
            )
          })}
        </CustomSlider>
      </StyledBlogCards>
    </StyledContainerWrap>
  )
}

export default withCustomErrorBoundary(BlogCards, 'blog-cards-hp')
